import { isElementVisibleWithinParent, findAncestorBySelector } from 'app/utils/helpers';
import { loadAsyncScript } from 'app/utils/ajaxHelpers';
import mediaQueries from 'app/utils/mediaQueries';
import './video.scss';

const { plyr } = window.inlineGlobalConfig.hashedPaths;
const videoBannerComponents = [...document.querySelectorAll('.video-banner')].filter(
  videoBanner => !findAncestorBySelector(videoBanner, '#content-spots')
);

if (videoBannerComponents.length) {
  loadAsyncScript(plyr).then(() => {
    const videoBannerComponentsInitialized = [];
    videoBannerComponents.forEach(videoBanner => {
      const videoId = videoBanner.id;
      const videoElement = videoBanner.querySelector('video');
      const { videoOptions, ctaBanner, url, target, linkName } = videoBanner.dataset;

      try {
        if (!videoOptions && !videoBanner.classList.contains('video-banner--in-media')) {
          throw Error('Options are empty');
        }

        const setClickableArea = entireVideoBanner => {
          const eventListenerFunc = event => {
            if (target.toUpperCase() === 'SAMEWINDOW') {
              window.location.href = url;
            } else {
              window.open(url);
            }
            event.stopPropagation();
          };

          const addOnClickFunc = el => {
            el.addEventListener('click', eventListenerFunc);
          };

          setTimeout(() => {
            if (entireVideoBanner) {
              const videoBannerWrapper = videoBanner.querySelector('.plyr__video-wrapper');

              videoBannerWrapper.classList.add('with-pointer-events');
              addOnClickFunc(videoBannerWrapper);
            } else {
              const realEstateCta = videoBanner.querySelector('.copy');
              if (realEstateCta) {
                const childElements = Array.prototype.slice.call(realEstateCta.children);
                childElements.forEach(el => {
                  el.classList.add('with-pointer-events');
                  addOnClickFunc(el);
                });
              }
            }
          }, 0);
        };

        if (linkName) {
          videoBanner.querySelector('a').classList.add('label-background');
        }
        if (ctaBanner === 'true') {
          setClickableArea(true);
        } else if (!linkName) {
          setClickableArea(false);
        }

        const options = videoOptions ? JSON.parse(videoOptions.replace(/&quot;/g, '"')) : {};
        const shadow = options.shadow && options.controls.length !== 0;
        if (!shadow) {
          setTimeout(() => {
            const playerControls = videoBanner.querySelector('.plyr__controls');
            playerControls.classList.add('no-shadow');
          }, 0);
        }
        const duration = options.displayTime || null;
        if (duration) {
          window.addEventListener('load', () => {
            const plyrEl = videoBanner.querySelector('.plyr');
            plyrEl.classList.add('plyr--hide-controls');
          });

          const handlePlyrControlVisibility = event => {
            const plyrEl = videoBanner.querySelector('.plyr');
            event.preventDefault();
            plyrEl.classList.remove('plyr--hide-controls');
            setTimeout(() => {
              plyrEl.classList.add('plyr--hide-controls');
            }, duration * 1000);
          };

          const handlePlyrOnResize = () => {
            const isSmall = mediaQueries.is_small_only();
            const isMedium = mediaQueries.is_medium_only();

            if (isSmall || isMedium) {
              videoBanner.removeEventListener('mouseenter', handlePlyrControlVisibility);
              videoBanner.addEventListener('click', handlePlyrControlVisibility);
            } else {
              videoBanner.removeEventListener('click', handlePlyrControlVisibility);
              videoBanner.addEventListener('mouseenter', handlePlyrControlVisibility);
            }
          };

          const handlePlyrControlOnResize = handlePlyrOnResize;
          handlePlyrControlOnResize();

          window.addEventListener('resize', handlePlyrControlOnResize);
        }
        const autoplay = options.autoplay && isElementVisibleWithinParent(videoElement);
        videoBannerComponentsInitialized.push({
          player: new window.Plyr(videoElement, {
            ...options,
            autoplay,
            hideControls: false,
          }),
          autoplay: options.autoplay,
          id: videoId,
        });
      } catch (err) {
        console.error(err);
      }
    });
    const handleVideosByVisibility = () => {
      let isSmallOnly = mediaQueries.is_small_only();
      let isMediumOnly = mediaQueries.is_medium_only();

      return () => {
        if (isSmallOnly !== mediaQueries.is_small_only() || isMediumOnly !== mediaQueries.is_medium_only()) {
          isSmallOnly = mediaQueries.is_small_only();
          isMediumOnly = mediaQueries.is_medium_only();
          videoBannerComponentsInitialized.forEach(video => {
            /* eslint-disable no-param-reassign */
            if (video.player.playing && !isElementVisibleWithinParent(document.querySelector(`#${video.id}`))) {
              video.player.pause();
              video.wasPlaying = true;
            } else if (!video.player.playing && (video.wasPlaying || video.autoplay)) {
              video.player.play();
              video.autoplay = false;
              video.wasPlaying = false;
            }
          });
        }
      };
    };

    const handleViewportChanges = handleVideosByVisibility();
    handleViewportChanges();

    window.addEventListener('resize', handleViewportChanges);
  });
}
